'use client';

import { createContext, useContext, useMemo, useReducer } from 'react';

import { SessionData } from '@/lib/getSessionPages';

interface SessionState {
	session?: SessionData;
}

type SessionContextInterface = SessionState;

const initialContext: SessionContextInterface = {};

const SessionContext = createContext<SessionContextInterface>(initialContext);

export const useSession = () => useContext(SessionContext) as SessionContextInterface;

type SessionActionTypes = { type: '@session/logout' };

function reducer(state: SessionState, action: SessionActionTypes): SessionState {
	switch (action.type) {
		case '@session/logout': {
			return state;
		}
		default: {
			return state;
		}
	}
}

interface SessionProviderProps {
	children: React.ReactNode;
	session: string;
}

export const SessionProvider = ({ children, session }: SessionProviderProps) => {
	const [state] = useReducer(reducer, { session: JSON.parse(session) });

	const sessionValue = useMemo(() => {
		return {
			...state
		};
	}, [state]);

	return <SessionContext.Provider value={sessionValue}>{children}</SessionContext.Provider>;
};
